.agreementsitem {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    gap: 1rem;
}

.agreementsimg {
    position: relative;
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
    flex-shrink: 0;
}

.agreementsimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.agreementscontent {
    display: flex;
    flex-direction: column;
}

.agreementscomment {
    font-family: 'Open Sans';
    color: #004aad;
}

.author {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.description p {
    margin: 0;
}

.description p:first-child {
    font-size: 0.85rem;
    color: #555;
    margin-bottom: 0.5rem;
}

.description p:last-child {
    font-size: 0.65rem;
    opacity: 0.8;
}

.comment {
    color: #004aad;
    font-weight: bold;
}
