.buttonwithicons {
    color: white;
    background-color: #004aad;
    border-radius: 50%;
    border: none;
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonwithicons:hover {
    background-color: #77b5e4;
}
