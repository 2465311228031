.card1 {
  max-width: 450;
  padding: 20px 5px;
  margin: 0 auto;
  border: none;
}

.typo1 {
  text-align: center;
  padding: 50px;
  font-family: 'Archivo Black';
  font-size: large;
  color: #004aad;
}
