.seccion {
    background-repeat: no-repeat;
    background-size: cover;
    /* La clase .test viene de Seccion.tsx */
}

@media screen and (max-width: 576px) {
    .test {
        width: 100%;
    }
}

@media screen and (max-width: 940px) {
    .test {
        width: 100%;
    }
}