.image {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  object-position: left;
}

.mainImage {
  width: 100%;
}

.mainImage__description {
  color: #444;
  font-weight: 300;
  font-size: 0.95rem;
  margin-top: 0.5rem;
}

.imageContainer {
  width: 100%;
  height: 300px;
  background-color: #fafafa;
}

.imageContainer img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: center;
}

header {
  font-size: 0.75rem;
}

header .category-name {
  font-weight: bold;
  font-size: 1.25em;
  text-transform: uppercase;
}

header .title {
  font-size: 3em;
  font-weight: bold;
}

header .intro {
  font-size: 1.15em;
  color: #444;
  font-weight: 300;
}

header .date-time {
  color: #444;
  font-weight: 300;
  font-size: 0.95em;
}

@media screen and (min-width: 978px) {
  header {
    font-size: 1rem;
  }

  .imageContainer {
    height: 500px;
  }
}
