.delegationsitem {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
}

.delegationspic {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.delegationtest {
    background-color: white;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Open Sans';
    color: #004aad;
}

.delegation-title {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.15rem;
    margin-bottom: 0.5rem;
}

.delegation-info-item {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    color: #888;
    margin-bottom: 0.35rem;
    font-size: 0.85rem;
}

.delegation-info-item svg {
    color: var(--main-color);
}

.delegation-info-item p {
    margin: 0;
}

@media screen and (max-width: 576px) {
    .delegationsitem {
        width: 100%;
    }
    .delegationspic {
        width: 100%;
    }
    .delegationtest {
        width: 100%;
    }
}

@media screen and (max-width: 940px) {
    .delegationsitem {
        width: 100%;
    }
    .delegationspic {
        width: 100%;
    }
    .delegationtest {
        width: 100%;
    }
}
