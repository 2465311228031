/* Inicio Seccion Uno */

.hometext {
  text-align: center;
  color: white;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.hometext h1 {
  font-family: 'Archivo Black';
  text-shadow: 2px 2px 2px grey;
  font-size: 1.75rem;
}

@media screen and (min-width: 478px) {
  .hometext h1 {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 976px) {
  .hometext h1 {
    font-size: 3.5rem;
  }
}

.btn-1 {
  position: relative;
  font-family: 'Open Sans';
  font-size: medium;
  color: white;
  background-color: #7ed957;
  border: none;
  border-radius: 10%;
  padding: 20px 20px 20px 20px;
  cursor: pointer;
}

.btn-1:hover {
  background-color: #77b5e4;
}

.homesection {
  height: 30vh;
  background-position: top center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 976px) {
  .homesection {
    height: 100vh;
  }
}

/* Fin Seccion Uno */

/* Inicio Seccion Dos */

.accordioncontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  transition: 0.5s;
}

/* Fin Seccion 2 */

/* Inicio Seccion 3 */
.quienes-somos-container {
  display: flex !important;
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
}

@media screen and (min-width: 976px) {
  .quienes-somos-container {
    flex-flow: row !important;
  }
}

.flexcontainer {
  display: flex;
  flex-flow: column;
  flex: 1 0;
  gap: 3rem;
}

.logocontainer {
  display: flex;
}

.aboutuslogo {
  width: 100px;
  object-fit: contain;
  object-position: center;
}

.carrusel {
  flex: 1 0;
  margin: 0 auto;
  border-top-left-radius: 25%;
  border-bottom-right-radius: 25%;
  overflow: hidden;
  box-sizing: border-box;
}

.aboutustittleone {
  position: relative;
  font-size: 300;
  font-size: 2rem;
  font-family: 'Archivo Black';
  text-shadow: 2px 2px 2px grey;
  color: #004aad;
  margin-bottom: 0 !important;
}

.aboutustext {
  font-family: 'Open Sans';
  color: #004aad;
}

/* Fin Seccion 3 */

/* Inicio Seccion 4 */

.newstittle {
  position: relative;
  text-align: center;
  color: white;
  font-family: 'Archivo Black';
  text-shadow: 2px 2px 2px grey;
}

.newscontainer {
  display: grid;
  grid-auto-flow: rows;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  justify-content: center;
}

@media screen and (min-width: 478px) {
  .newscontainer {
    gap: 2rem;
    grid-template-columns: repeat(2, 0.5fr);
  }
}

@media screen and (min-width: 978px) {
  .newscontainer {
    grid-template-columns: repeat(3, 0.3fr);
  }
}

/* Fin Seccion 4 */

/* Inicio Seccion 5 */

.delegationstittle {
  position: relative;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 2px grey;
  font-family: 'Archivo Black';
}

.delegationscontainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin: 40px auto;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 748px) {
  .delegationscontainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 968px) {
  .delegationscontainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Fin Seccion 5 */

/* Inicio Seccion 6 */

.agreementstittle {
  position: relative;
  text-align: center;
  color: #004aad;
  text-shadow: 2px 2px 2px grey;
  font-family: 'Archivo Black';
}

.agreementssubtittle {
  position: relative;
  text-align: center;
  color: #77b5e4;
  text-shadow: 2px 2px 2px grey;
  font-family: 'Archivo Black';
}

.agreementstext {
  position: relative;
  text-align: center;
  color: #004aad;
  font-family: 'Open Sans';
  max-width: 1000px;
  margin: 0 auto;
}

.agreementscontainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-flow: rows;
  gap: 3rem;
  margin: 0 auto;
  margin-top: 5rem;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
}

@media screen and (min-width: 478px) {
  .agreementscontainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 976px) {
  .agreementscontainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Fin Seccion 6 */

/* Inicio NavBar */

.homebutton {
  cursor: pointer;
  margin: 3px;
}

.logoytexto {
  height: 120px;
  width: 120px;
}

.icons {
  width: 25px;
  height: 25px;
}

.textdecoration {
  text-decoration: none;
  font-family: 'Archivo Black';
  font-size: larger;
  color: #004aad;
}

.ahrefcontainer {
  display: flex;
  gap: 2rem;
}

/* Fin NavBar */

.paletadecolores {
  color: #004aad;
  color: #00bcf2;
  color: #77b5e4;
  color: #7ed957;
}

.contactanosTitle {
  color: #004aad;
  font-family: 'Archivo Black';
  text-align: center;
}

h2 {
  margin-bottom: 3rem !important;
  font-size: 1.75rem;
}

@media screen and (min-width: 478px) {
  h2 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 976px) {
  h2 {
    font-size: 3.5rem;
  }
}

.wp-contact {
  max-width: 1200px;
  margin: 0 auto;
}

.wp-contact > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
}

@media screen and (min-width: 976px) {
  .wp-contact > div {
    flex-direction: row;
    gap: 3rem;
  }
}

.wp-contact > div > div {
  width: 100%;
}

.wp-contact img {
  width: 50px;
}

.whatsapp-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  text-decoration: none;
  gap: 1rem;
  width: 100%;
  flex-shrink: 0;
}

.whatsapp-link .link-title {
  color: #004aad;
}

.whatsapp-link .link-description {
  color: #999;
  font-size: 0.85rem;
}

.whatsapp-link .content {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.whatsapp-link .content span {
  text-decoration: none;
}
