.newsitem {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    cursor: pointer;
}

.newspic {
    height: 150px;
    width: 200px;
    object-fit: contain;
    object-position: center;
    flex-shrink: 0;
}

.newsdesc {
    background-color: white;
    width: 100%;
    padding: 0.75rem;
    box-sizing: border-box;
    text-align: left;
    font-family: 'Open Sans';
    color: #888;
    font-size: 0.75rem;
}

.newsdesc header {
    font-size: 0.65rem;
    color: #999;
}

.newsTitle {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444;
    font-size: 1.15rem;
}

.newsdesc p {
    margin-bottom: 0;
}

@media screen and (max-width: 576px) {
    .newsitem {
        width: 100%;
    }
    .newspic {
        width: 100%;
    }
    .newstest {
        width: 100%;
    }
}

@media screen and (max-width: 940px) {
    .newsitem {
        width: 100%;
    }
    .newspic {
        width: 100%;
    }
    .newstest {
        width: 100%;
    }
}
